import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { Domain } from "./features/domain";
import { Domains } from "./features/domains";
import { FeatureRelease } from "./features/feature";
import { Features } from "./features/features";
import { Home } from "./features/home";
import { NewDomain } from "./features/new-domain";
import { OrganizationContext } from "./features/organization";

export function AppRoutes() {
  const { path } = useRouteMatch();
  const { user, logout } = useAuth0();
  const { org }: any = useParams();
  const orgContext = useContext(OrganizationContext);
  useEffect(() => {
    if (orgContext?.orgs) {
      const currentOrg = orgContext.orgs.find(
        (o) => o.name?.toLowerCase() === org
      );

      if (currentOrg && currentOrg.name !== orgContext.currentOrg?.name) {
        orgContext?.setCurrentOrg(currentOrg);
      }
    }
  }, [orgContext]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Home />
      </Route>
      <Route exact path={`${path}/domains`}>
        <Domains />
      </Route>
      <Route path={`${path}/domains/new`}>
        <NewDomain />
      </Route>
      <Route path={`${path}/domains/:domain`}>
        <Domain />
      </Route>
      <Route exact path={`${path}/features`}>
        <Features />
      </Route>
      <Route path={`${path}/features/:feature/release`}>
        <FeatureRelease />
      </Route>
      <Route path={`${path}/apps`}>
        <div className="feature-container">
          <div className={`feature-content w-lg space-y-lg`}>
            <div className="title">Apps</div>
          </div>
        </div>
      </Route>
      <Route path={`${path}/settings`}>
        <div className="feature-container">
          <div className={`feature-content w-lg space-y-lg`}>
            <div className="title">Settings</div>
            {/* <div>
              <button onClick={() => callApi()} type="submit">
                Call API
              </button>
              <div>apiMessage: {state.apiMessage}</div>
              <div>{JSON.stringify(user, null, 2)}</div>
              <button
                onClick={() => logout({ returnTo: "http://localhost:3000" })}
                type="submit"
              >
                Sign out
              </button>
            </div> */}
            <div>{JSON.stringify(user, null, 2)}</div>
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
              type="submit"
            >
              Sign out
            </button>
          </div>
        </div>
      </Route>
    </Switch>
  );
}
