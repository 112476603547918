import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Handle, Position } from "react-flow-renderer";
import nodeStyles from "./Node.module.css";
import styles from "./ActionStepNode.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faCheckDouble,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";

export type ActionStepNodeProps = {};

export function ActionStepNode(props: any): JSX.Element {
  let typeLabel: string = "";
  let icon: IconProp = faPlay;
  let iconColor: string = "";
  let iconBgColor: string = "#fff";

  switch (props.data?.type) {
    case "trigger":
      typeLabel = "Trigger";
      icon = faPlay;
      iconColor = "#2dd4bf";
      break;
    case "execute":
      typeLabel = "Action";
      icon = faCheckDouble;
      iconColor = "#2dd4bf";
      break;
    case "outcome-success":
      typeLabel = "Outcome";
      icon = faCheckCircle;
      iconColor = "#4ade80";
      iconBgColor = "#fff";
      break;
    case "outcome-failure":
      typeLabel = "Outcome";
      icon = faTimesCircle;
      iconColor = "#ef4444";
      break;
    case "event":
      typeLabel = "Event";
      icon = faBolt;
      iconColor = "#eab308";
      break;
    case "sendgrid":
      typeLabel = "Action";
      break;
  }

  return (
    <div className={styles["node"]}>
      <div className={`${styles["header"]} space-x-md`}>
        <div
          style={{ backgroundColor: iconBgColor }}
          className={styles["icon"]}
        >
          {props.data?.type !== "sendgrid" ? (
            <FontAwesomeIcon
              style={{ color: iconColor }}
              className="text-3xl"
              icon={icon}
            />
          ) : (
            <img src="/SendGrid-Logomark.png" />
          )}
        </div>
        <div className="flex flex-column flex-1">
          <div className="very-subtle">{typeLabel}</div>
          <div className="title-secondary">{props.data.label}</div>
        </div>
      </div>
      {props.data?.type !== "trigger" && (
        <Handle
          type="target"
          position={Position.Top}
          className={`${nodeStyles["handle"]} ${styles["handle"]}`}
        />
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        className={`${nodeStyles["handle"]} ${styles["handle"]}`}
      />
    </div>
  );
}
