import React, { PropsWithChildren } from "react";
import styles from "./Persona.module.css";

export type PersonaProps = PropsWithChildren<{
  backgroundColor?: string;
  isSquare?: boolean;
  isSmall?: boolean;
  className?: string;
}>;

export function Persona(props: PersonaProps): JSX.Element {
  return (
    <div
      style={{ backgroundColor: props.backgroundColor }}
      className={`${styles["persona"]} ${
        props.isSquare ? styles["square"] : styles["circle"]
      } ${props.isSmall ? styles["small"] : ""} ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
}
