import {
  faChevronDown,
  faChevronUp,
  faPlusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import {} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { DivideStyle, ListView } from "../../components/list";
import { FeatureNav, TopNav } from "../../components/nav";
import { Select } from "../../components/select";

import styles from "./FeatureRelease.module.css";
import { Persona } from "../../components/persona";
import { SearchInput } from "../../components/input/SearchInput";

export type FeatureReleaseProps = {};

export function FeatureRelease(props: FeatureReleaseProps): JSX.Element {
  document.title = `Release My first feature - Swap`;
  const [currentStep, setCurrentStep] = useState<number>(1);

  return (
    <div className="feature-container">
      <TopNav>
        <div className="flex flex-1">
          <div>Features</div>
          <div className="p-lg">/</div>
          <div className="">First Feature</div>
          <div className="p-lg">/</div>
          <div className="">Release</div>
        </div>
        <FeatureNav featureName="First feature"></FeatureNav>
      </TopNav>
      <TopNav onlySidePadding>
        <div className="flex w-1/5">
          <div className="flex flex-column">
            <div className="title-tertiary subtle">Release Feature</div>
            <div className="title-secondary">First Feature</div>
          </div>
        </div>
        <div className="flex flex-1 items-center content-center">
          <div
            className={`${styles["step"]} ${
              currentStep === 1 ? styles["current-step"] : ""
            } ${
              currentStep > 1 ? styles["step-complete"] : ""
            } text-sm space-x-lg`}
          >
            <div className={`${styles["circle"]}`}>
              {currentStep > 1 ? <FontAwesomeIcon icon={faCheck} /> : "01"}
            </div>
            <div className="flex flex-column">
              <div className="subtle">Changes</div>
              <div className="">
                {currentStep > 1 ? "4 changes reviewed" : "Review 4 changes"}
              </div>
            </div>
          </div>
          <div
            className={`${styles["step"]} ${styles["not-first-step"]} ${
              currentStep === 2 ? styles["current-step"] : ""
            } ${currentStep < 2 ? styles["inactive"] : ""} ${
              currentStep > 2 ? styles["step-complete"] : ""
            } space-x-lg text-sm`}
          >
            <div className={`${styles["circle"]}`}>
              {currentStep > 2 ? <FontAwesomeIcon icon={faCheck} /> : "02"}
            </div>
            <div className="flex flex-column">
              <div className="subtle">Implementation</div>
              <div>Make selections </div>
            </div>
          </div>
          <div
            className={`${styles["last-step"]} ${
              currentStep === 3 ? styles["current-step"] : ""
            } ${currentStep < 3 ? styles["inactive"] : ""} text-sm space-x-lg`}
          >
            <div className={`${styles["circle"]}`}>03</div>
            <div className="flex flex-column">
              <div className="subtle">Review</div>
              <div>Review and release</div>
            </div>
          </div>
        </div>
        <div className="flex w-1/5"></div>
      </TopNav>
      {currentStep === 1 && (
        <div className={`feature-content w-xl space-y-2xl`}>
          <div className="title">Review changes</div>
          <div className="flex space-x-xl">
            <div className={`${styles["outline"]} w-full space-y-xl`}>
              <div className="flex space-x-md">
                <SearchInput className="flex-1" placeholder="Filter changes" />
                <Select className="w-sm">All users</Select>
              </div>
              <div className="flex flex-column">
                <div className={styles["multistep"]}>
                  <div
                    className={`${styles["outline-change"]} ${styles["selected"]} space-x-sm`}
                  >
                    <FontAwesomeIcon
                      className={`${styles["addition"]}`}
                      icon={faPlusCircle}
                    />
                    <div className={`flex-1 ${styles["change-name"]}`}>
                      Article
                    </div>
                    <Persona
                      className={`${styles["user"]}`}
                      backgroundColor="#5EEAD4"
                      isSmall
                    >
                      AB
                    </Persona>
                    <div className={`subtle`}>Domain</div>
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                  <div className={styles["substeps"]}>
                    <div className={`${styles["line"]}`}></div>
                    <div className="flex flex-column flex-1">
                      <div
                        className={`${styles["outline-change"]} ${styles["indent"]} space-x-sm`}
                      >
                        <FontAwesomeIcon
                          className={`${styles["addition"]}`}
                          icon={faPlusCircle}
                        />
                        <div className={`flex-1 ${styles["change-name"]}`}>
                          Properties
                        </div>
                        <div className={`subtle`}>Model</div>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                      <div
                        className={`${styles["outline-change"]} ${styles["indent"]} space-x-sm`}
                      >
                        <FontAwesomeIcon
                          className={`${styles["addition"]}`}
                          icon={faPlusCircle}
                        />
                        <div className={`flex-1 ${styles["change-name"]}`}>
                          Create Article
                        </div>
                        <div className={`subtle`}>Action</div>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                      <div
                        className={`${styles["outline-change"]} ${styles["indent"]} space-x-sm`}
                      >
                        <FontAwesomeIcon
                          className={`${styles["addition"]}`}
                          icon={faPlusCircle}
                        />
                        <div className={`flex-1 ${styles["change-name"]}`}>
                          Query Articles
                        </div>
                        <div className={`subtle`}>Action</div>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full content-center">
                <button
                  onClick={() => setCurrentStep(2)}
                  type="button"
                  className="primary flex w-1/2 space-x-sm"
                >
                  <div>Continue</div>
                  {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div className={`feature-content w-xl space-y-xl`}>
          <div className="title">Select implementation</div>
          <ListView
            divideStyle={DivideStyle.Line}
            items={[
              <div className="section space-y-sm">
                <div className="title-tertiary">Application</div>
                <div className="flex space-x-md">
                  <div className="flex flex-column">
                    <div className=""></div>
                    <div className="card selected">
                      <div className="title-tertiary">
                        <img
                          alt="node"
                          className={styles["logo"]}
                          src="/1280px-Node.js_logo_2015.svg.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="v-separator"></div>
                  <div className="section">Node.js GraphQL Web API</div>
                </div>
                <div className={styles["separator"]} />
              </div>,
              <div className="section">
                <div className="title-tertiary">Cloud Provider</div>
                <div className="">AWS</div>
                <div className={styles["separator"]} />
              </div>,
              <div className="section">
                <div className="title-tertiary">State</div>
                <div className="">MongoDB</div>
                <div className={styles["separator"]} />
              </div>,
              <div className="section">
                <div className="title-tertiary">Security</div>
                <div className="">Auth0</div>
                <div className={styles["separator"]} />
              </div>,
              <div className="section">
                <div className="title-tertiary">Email Delivery Service</div>
                <div className="">SendGrid</div>
                <div className={styles["separator"]} />
              </div>,
            ]}
          />
          {/* <div className="flex flex-column">
            <div className="section">Application</div>
          </div> */}
          <div className="flex w-full content-center">
            <button
              onClick={() => setCurrentStep(3)}
              type="button"
              className="primary flex space-x-sm w-1/2"
            >
              <div>Continue</div>
              {/* <FontAwesomeIcon icon={faArrowRight} /> */}
            </button>
          </div>
        </div>
      )}
      {currentStep === 3 && (
        <>
          <div>Release</div>{" "}
          <button
            onClick={() => setCurrentStep(1)}
            type="button"
            className="primary flex space-x-sm"
          >
            <div>Continue</div>
          </button>
        </>
      )}
    </div>
  );
}
