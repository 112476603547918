import dagre from "dagre";
import { Elements, isNode } from "react-flow-renderer";

export const getLayoutedElements = (
  elements: Elements,
  nodeWidth: number,
  nodeHeight: number,
  direction = "LR"
) => {
  // const nodeWidth = 900;
  // const nodeHeight = 70;
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      (el.targetPosition as any) = isHorizontal ? "left" : "top";
      (el.sourcePosition as any) = isHorizontal ? "right" : "bottom";

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};
