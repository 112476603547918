import React, { MouseEvent } from "react";
import ReactFlow, { Background, Controls, MiniMap } from "react-flow-renderer";
import { getActionElements } from "./actionElements";
import buildStyles from "../Build.module.css";
import styles from "./Action.module.css";
import { ActionStepNode } from "../flow/ActionStepNode";
import { IconLink } from "../../../../components/link";
import {
  faArrowLeft,
  faChevronDown,
  faCog,
  faFlask,
  faHistory,
  faStream,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBolt,
  faPlay,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLayoutedElements } from "../flow/layoutedElements";
import { ExecuteActionNode } from "../flow/ExecuteActionNode";
import { faCheckDouble } from "@fortawesome/pro-duotone-svg-icons";
import { SearchInput } from "../../../../components/input/SearchInput";

export function Action(): JSX.Element {
  const { path, url } = useRouteMatch();
  const elements = getLayoutedElements(getActionElements(), 400, 150, "TD");

  let flowInstance: any = null;
  const onLoad = (reactFlowInstance: any) => {
    flowInstance = reactFlowInstance;
    // reactFlowInstance.fitView();
  };

  const onPaneClick = (event: MouseEvent) => {};

  const nodeTypes = {
    step: ActionStepNode,
  };

  return (
    <div className="flex flex-column space-y-sm">
      <div className="flex">
        <div
          className={`${buildStyles["intentions-flow"]} ${buildStyles["flow-action-height"]}`}
        >
          <ReactFlow
            elements={elements}
            nodesDraggable={false}
            // onElementClick={onElementClick}
            onLoad={onLoad}
            nodeTypes={nodeTypes}
            // onNodeDoubleClick={onNodeDoubleClick}
            onPaneClick={onPaneClick}
          >
            <div className={`${buildStyles["intention-container"]} w-sm`}>
              <div className={`${buildStyles["intention"]} space-y-lg`}>
                <div className="flex items-center">
                  <div className="flex flex-start w-1/4">
                    <IconLink
                      to="/domains/article"
                      icon={faArrowLeft}
                      solidIcon={faArrowLeft}
                    >
                      {/* Back to domain */}
                    </IconLink>
                  </div>
                  <div className="flex flex-1 font-medium content-center">
                    <div>Create Article</div>
                  </div>
                  <div
                    className={`flex flex-start w-1/4 content-end subtle ${styles["badge"]}`}
                  ></div>
                </div>
                <div className="flex content-center space-x-sm">
                  <NavLink
                    exact
                    to={`${url}`}
                    className={`${buildStyles["navlink"]} flex flex-1 content-center items-center space-x-sm`}
                  >
                    <FontAwesomeIcon icon={faStream} />
                    {/* <div>Outline</div> */}
                  </NavLink>
                  <NavLink
                    to={`${url}/tests`}
                    className={`${buildStyles["navlink"]} flex flex-1 content-center items-center space-x-sm`}
                  >
                    <FontAwesomeIcon icon={faFlask} />
                    {/* <div>Tests</div> */}
                  </NavLink>
                  <NavLink
                    to={`${url}/history`}
                    className={`${buildStyles["navlink"]} flex flex-1 content-center items-center space-x-sm`}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                    {/* <div>History</div> */}
                  </NavLink>
                  <NavLink
                    to={`${url}/settings`}
                    className={`${buildStyles["navlink"]} flex flex-1 content-center items-center space-x-sm`}
                  >
                    <FontAwesomeIcon icon={faCog} />
                    {/* <div>Settings</div> */}
                  </NavLink>
                </div>
                <Switch>
                  <Route exact path={`${path}`}>
                    <div className="flex flex-column space-y-lg">
                      <SearchInput placeholder="Filter steps" />
                      <div className="flex flex-column">
                        <div
                          className={`flex items-center space-x-sm ${styles["outline-step"]} ${styles["selected"]}`}
                        >
                          <div
                            className={`${styles["outline-step-icon"]} ${styles["trigger"]}`}
                          >
                            <FontAwesomeIcon className={``} icon={faPlay} />
                          </div>
                          <div className="flex-1">On-Demand</div>
                          <div className={`subtle`}>Trigger</div>
                        </div>
                        <div className={styles["multistep"]}>
                          <div
                            className={`flex items-center space-x-sm ${styles["outline-step"]}`}
                          >
                            <div
                              className={`${styles["outline-step-icon"]} ${styles["commit"]}`}
                            >
                              <FontAwesomeIcon
                                className={``}
                                icon={faCheckDouble}
                              />
                            </div>
                            <div className="flex-1">Commit</div>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                          <div className={styles["substeps"]}>
                            <div className={styles["line"]}></div>
                            <div className="flex flex-1 flex-column">
                              <div className={styles["multistep-2"]}>
                                <div
                                  className={`flex items-center space-x-sm ${styles["outline-step"]} ${styles["indent"]}`}
                                >
                                  <div
                                    className={`${styles["outline-step-icon"]} ${styles["success"]}`}
                                  >
                                    <FontAwesomeIcon
                                      className={``}
                                      icon={faCheckCircle}
                                    />
                                  </div>
                                  <div className="flex-1">Success</div>
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                                <div className={styles["substeps"]}>
                                  <div className={`${styles["line-2"]}`}></div>
                                  <div className="flex flex-column flex-1">
                                    <div
                                      className={`flex items-center space-x-sm ${styles["outline-step"]} ${styles["indent"]}`}
                                    >
                                      <div
                                        className={`${styles["outline-step-icon"]} ${styles["event"]}`}
                                      >
                                        <FontAwesomeIcon
                                          className={``}
                                          icon={faBolt}
                                        />
                                      </div>
                                      <div className="flex-1">
                                        Article Created
                                      </div>
                                      <div className="subtle">Event</div>
                                    </div>
                                    <div
                                      className={`flex items-center space-x-sm ${styles["outline-step"]} ${styles["indent"]}`}
                                    >
                                      <div
                                        className={`${styles["outline-step-icon"]} ${styles["event"]}`}
                                      >
                                        <img src="/SendGrid-Logomark.png" />
                                      </div>
                                      <div className="flex-1">
                                        Notify Followers
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles["multistep-2"]}>
                                <div
                                  className={`flex items-center space-x-sm ${styles["outline-step"]} ${styles["indent"]}`}
                                >
                                  <div
                                    className={`${styles["outline-step-icon"]} ${styles["failure"]}`}
                                  >
                                    <FontAwesomeIcon
                                      className={``}
                                      icon={faTimesCircle}
                                    />
                                  </div>
                                  <div className="flex-1">Failure</div>
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                                <div className={styles["substeps"]}>
                                  <div className={`${styles["line-2"]}`}></div>
                                  <div className="flex flex-column flex-1">
                                    <div
                                      className={`flex items-center space-x-sm ${styles["outline-step"]} ${styles["indent"]}`}
                                    >
                                      <div
                                        className={`${styles["outline-step-icon"]} ${styles["event"]}`}
                                      >
                                        <FontAwesomeIcon
                                          className={``}
                                          icon={faBolt}
                                        />
                                      </div>
                                      <div className="flex-1">
                                        Create Article Failed
                                      </div>
                                      <div className="subtle">Event</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route path={`${path}/tests`}>tests</Route>
                  <Route path={`${path}/history`}>history</Route>
                  <Route path={`${path}/settings`}>settings</Route>
                </Switch>
              </div>
            </div>
            <MiniMap nodeBorderRadius={2} />
            <Controls />
            <Background color="#18181b" gap={16} />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}
