import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Input.module.css";

export type SearchInputProps = { placeholder?: string; className?: string };

export function SearchInput(props: SearchInputProps): JSX.Element {
  return (
    <div
      className={`${styles["search"]} flex ${
        props.className ? props.className : ""
      }`}
    >
      <div className={`${styles["search-icon"]}`}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <input
        style={{ paddingLeft: "50px", width: "100%" }}
        type="text"
        placeholder={props.placeholder ? props.placeholder : ""}
      />
    </div>
  );
}
