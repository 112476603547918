import React from "react";
import { FeatureNav, TopNav } from ".";
import { Breadcrumb } from "./Breadcrumb";

export function AppTopNav(): JSX.Element {
  return (
    <TopNav>
      <div className="flex flex-1 items-center">
        <Breadcrumb />
      </div>
      <FeatureNav featureName="First feature"></FeatureNav>
    </TopNav>
  );
}
