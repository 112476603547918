import { createContext } from "react";
import { Organization } from "./Organization";

export const OrganizationContext = createContext<
  OrganizationContextType | undefined
>(undefined);

export type OrganizationContextType = {
  currentOrg?: Organization;
  setCurrentOrg: (org: Organization) => void;
  orgs?: Organization[];
  setOrgs: (orgs: Organization[]) => void;
};
