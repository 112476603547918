import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";
import styles from "./SelectCard.module.css";

export type SelectCardProps = PropsWithChildren<{ selected?: boolean }>;

export function SelectCard(props: SelectCardProps): JSX.Element {
  return (
    <div
      className={`flex items-center space-x-lg ${styles["select-card"]} ${
        props.selected ? styles["selected"] : ""
      }`}
    >
      <div className={`${styles["radio"]} text-xl`}>
        <FontAwesomeIcon icon={props.selected ? faDotCircle : faCircle} />
      </div>
      <div>{props.children}</div>
    </div>
  );
}
