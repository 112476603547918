import { ArrowHeadType, Elements } from "react-flow-renderer";

const position = { x: 0, y: 0 };
export function getActionElements() {
  return [
    {
      id: "trigger",
      sourcePosition: "top",
      type: "step",
      data: { label: "On-Demand", type: "trigger" },
      position: position,
    },
    {
      id: "commit",
      sourcePosition: "top",
      targetPosition: "bottom",
      data: { label: "Commit", type: "execute" },
      position: position,
      type: "step",
    },
    {
      id: "success",
      sourcePosition: "top",
      targetPosition: "bottom",
      data: { label: "Success", type: "outcome-success" },
      position: position,
      type: "step",
    },
    {
      id: "failure",
      sourcePosition: "top",
      targetPosition: "bottom",
      data: { label: "Failure", type: "outcome-failure" },
      position: position,
      type: "step",
    },
    {
      id: "event",
      sourcePosition: "top",
      targetPosition: "bottom",
      data: { label: "Article Created", type: "event" },
      position: position,
      type: "step",
    },
    {
      id: "event2",
      sourcePosition: "top",
      targetPosition: "bottom",
      data: { label: "Create Article Failed", type: "event" },
      position: position,
      type: "step",
    },
    {
      id: "sendgrid",
      sourcePosition: "top",
      targetPosition: "bottom",
      data: { label: "Notify Followers", type: "sendgrid" },
      position: position,
      type: "step",
    },
    {
      id: "e1",
      source: "trigger",
      type: "smoothstep",
      target: "commit",
      arrowHeadType: ArrowHeadType.ArrowClosed,
      animated: true,
    },
    {
      id: "e2",
      source: "commit",
      type: "smoothstep",
      target: "success",
      arrowHeadType: ArrowHeadType.ArrowClosed,
      animated: true,
    },
    {
      id: "e3",
      source: "commit",
      type: "smoothstep",
      target: "failure",
      arrowHeadType: ArrowHeadType.ArrowClosed,
      animated: true,
    },
    {
      id: "e4",
      source: "success",
      type: "smoothstep",
      target: "event",
      arrowHeadType: ArrowHeadType.ArrowClosed,
      animated: true,
    },
    {
      id: "e5",
      source: "success",
      type: "smoothstep",
      target: "sendgrid",
      arrowHeadType: ArrowHeadType.ArrowClosed,
      animated: true,
    },
    {
      id: "e6",
      source: "failure",
      type: "smoothstep",
      target: "event2",
      arrowHeadType: ArrowHeadType.ArrowClosed,
      animated: true,
    },
  ] as Elements;
}
