import styles from "./FeatureNav.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { Persona } from "../persona";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { SearchInput } from "../input/SearchInput";
import { Feature } from "../../features/feature";
import { gql, useQuery } from "@apollo/react-hooks";

export type FeatureNavProps = { featureName: string };

export function FeatureNav(props: FeatureNavProps): JSX.Element {
  const { org }: any = useParams();
  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const [features, setFeatures] = useState<Feature[] | undefined>(undefined);
  const [currentFeature, setCurrentFeature] = useState<Feature | undefined>(
    undefined
  );

  const GET_FEATURES = gql`
    query {
      features {
        slug
        name
        description
        status
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_FEATURES, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const featureSlug = queryParams.get("feature");
    if (data) {
      setFeatures(
        (data.features as Feature[]).filter((f) => f.slug !== featureSlug)
      );
      if (featureSlug) {
        const feature = (data.features as Feature[]).find(
          (f) => f.slug === featureSlug
        );
        setCurrentFeature(feature);
      } else {
        setCurrentFeature(undefined);
      }
    }
  }, [data, location.search]);

  // close menu on route change
  useEffect(() => {
    const unlisten = history.listen(() => {
      setMenuIsOpen(false);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <div className="flex items-center space-x-sm">
      {currentFeature && (
        <>
          <div className={`${styles["status"]} flex items-center space-x-xs`}>
            <FontAwesomeIcon
              className={`${styles["feature-status-icon"]} text-sm`}
              icon={faCircle}
            />
            <div className={`${styles["status-text"]} link-button`}>
              {currentFeature.status}
            </div>
          </div>
          {/* <div className={styles["separator"]}></div> */}
          {/* <div className="">Show changes</div> */}
          <div className={styles["separator"]}></div>
        </>
      )}
      <div
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        className={`${styles["feature"]} space-x-sm`}
      >
        <FontAwesomeIcon icon={faCodeBranch} />
        <div className="noselect">
          {currentFeature ? currentFeature.name : "Live"}
        </div>
        {menuIsOpen ? (
          <FontAwesomeIcon className="text-sm" icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon className="text-sm" icon={faChevronDown} />
        )}
      </div>
      {menuIsOpen && (
        <div className={`${styles["menu"]}`}>
          {currentFeature && (
            <div
              className={`${styles["feature-detail"]} flex flex-column space-y-lg`}
            >
              <div className="flex items-center">
                <div className="flex flex-1 title-secondary">
                  <span>{currentFeature.name}</span>
                </div>
                <div className="flex space-x-xs items-center">
                  <span className="very-subtle">Created today by</span>
                  <Persona backgroundColor="#5EEAD4" isSmall>
                    AB
                  </Persona>
                </div>
                {/* <Link
                  className="link"
                  to={{
                    pathname: `/${org}/features`,
                    search: location.search,
                  }}
                >
                  Details
                </Link> */}
              </div>
              {/* <div className="flex space-x-xs items-center">
                <span className="very-subtle">Created today by</span>
                <Persona backgroundColor="#5EEAD4" isSmall>
                  AB
                </Persona>
              </div> */}
              <div className="flex flex-column space-y-xs">
                <Link
                  className="flex"
                  to={{
                    pathname: "/features/first-feature",
                    search: location.search,
                  }}
                >
                  <button className="flex-1 secondary" type="button">
                    <div className="flex items-center space-x-sm">
                      <div>More details</div>
                    </div>
                  </button>
                </Link>
                <Link
                  className="flex"
                  to={{
                    pathname: `/${org}/features/first-feature/release`,
                    search: location.search,
                  }}
                >
                  <button className={`primary flex-1`} type="button">
                    <div className="flex items-center space-x-sm">
                      <div>Release</div>
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          )}
          <div
            className={`${styles["feature-list"]} flex flex-column space-y-lg`}
          >
            <div className="flex">
              <div className="flex-1 title-tertiary">Switch features</div>
              <Link
                className="link"
                to={{
                  pathname: `/${org}/features`,
                  search: location.search,
                }}
              >
                View all features
              </Link>
            </div>
            <SearchInput placeholder="Filter features" />
            {currentFeature && (
              <Link to={{ pathname: location.pathname }}>
                <div className="card">
                  <div className="flex items-center">
                    <div className="flex-1 font-medium">Live</div>
                    <div className="very-subtle">Latest release</div>
                  </div>
                </div>
              </Link>
            )}
            {currentFeature && features && features.length > 0 && (
              <div className="h-separator"></div>
            )}
            {features &&
              features.map((feature, index) => (
                <Link
                  key={index}
                  to={{
                    pathname: location.pathname,
                    search: `feature=${feature.slug}`,
                  }}
                >
                  <div key={index} className="card">
                    <div className="flex">
                      <div className="flex flex-1 flex-column">
                        <div className="flex">
                          <div className="font-medium flex-1">
                            {feature.name}
                          </div>
                          <div
                            className={`${styles["status"]} text-sm flex items-center space-x-sm`}
                          >
                            <FontAwesomeIcon
                              className={`${styles["feature-status-icon"]}`}
                              icon={faCircle}
                            />
                            <div
                              className={`${styles["status-text"]} very-subtle`}
                            >
                              In progress
                            </div>
                          </div>
                        </div>
                        <div className="very-subtle">Updated today</div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
