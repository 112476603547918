import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faBell,
  faBrowser,
  faCodeBranch,
  faCog,
  faCube,
  faHome,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBell as faBellSolid,
  faBrowser as faBrowserSolid,
  faCog as faCogSolid,
  faCube as faCubeSolid,
  faHome as faHomeSolid,
  faSearch as faSearchSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { PropsWithChildren, useContext } from "react";
import { Persona } from "../persona/Persona";
import styles from "./AppNav.module.css";
import { IconLink } from "../link/IconLink";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Nav } from "./Nav";
import { OrganizationContext } from "../../features/organization";
import { useAuth0 } from "@auth0/auth0-react";

export type AppNavProps = PropsWithChildren<{
  logo: string;
}>;

export function AppNav(props: AppNavProps): JSX.Element {
  const { path, url } = useRouteMatch();
  const { org }: any = useParams();
  const location = useLocation();
  const orgContext = useContext(OrganizationContext);
  const { user } = useAuth0();

  return (
    <Nav className={`${styles["app-nav"]} text-xl`}>
      <div className={`${styles["header"]} space-y-xs`}>
        <Link to={{ pathname: `/${org}`, search: location.search }}>
          <img className={`${styles["logo"]}`} src={props.logo} alt="logo" />
        </Link>
        <IconLink
          isNavLink
          big
          to={{ pathname: `/${org}/search`, search: location.search }}
          icon={faSearch}
          solidIcon={faSearchSolid}
        />
        <IconLink
          isNavLink
          big
          to={`/${org}/notifications`}
          icon={faBell}
          solidIcon={faBellSolid}
        />
        <div className={styles["separator"]}></div>
        <Link to={{ pathname: `/`, search: location.search }}>
          <Persona isSquare backgroundColor={orgContext?.currentOrg?.color}>
            {orgContext?.currentOrg?.name?.charAt(0)}
          </Persona>
        </Link>
        <IconLink
          isNavLink
          big
          to={{ pathname: `/${org}`, search: location.search }}
          icon={faHome}
          solidIcon={faHomeSolid}
          exact
        />
        <IconLink
          isNavLink
          big
          to={{ pathname: `/${org}/domains`, search: location.search }}
          icon={faCube}
          solidIcon={faCubeSolid}
        />
        <IconLink
          isNavLink
          big
          to={{ pathname: `/${org}/features`, search: location.search }}
          icon={faCodeBranch}
          solidIcon={faCodeBranch}
        />
        <IconLink
          isNavLink
          big
          to={{ pathname: `/${org}/apps`, search: location.search }}
          icon={faBrowser}
          solidIcon={faBrowserSolid}
        />
        <IconLink
          isNavLink
          big
          to={{ pathname: `/${org}/settings`, search: location.search }}
          icon={faCog}
          solidIcon={faCogSolid}
        />
      </div>
      <div className={`${styles["footer"]} space-y-2xl`}>
        <FontAwesomeIcon className="" icon={faQuestionCircle} />
        <Persona className="text-lg" backgroundColor="#5EEAD4">
          <div>{`${user?.given_name?.charAt(0)}${user?.family_name?.charAt(
            0
          )}`}</div>
        </Persona>
        <FontAwesomeIcon className="" icon={faAngleDoubleRight} />
      </div>
    </Nav>
  );
}
