import { TopNav } from "../../components/nav";
import styles from "./Domain.module.css";
import {
  faChartLine,
  faCog,
  faHistory,
  faHome,
  faPencilRuler,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Build } from "./build";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { OrganizationContext } from "../organization";
import { BreadcrumbContext } from "../../components/nav/breadcrumb-context";

export function Domain(): JSX.Element {
  const { org }: any = useParams();
  const location = useLocation();
  const history = useHistory();
  const orgContext = useContext(OrganizationContext);
  document.title = `Article Domain - ${orgContext?.currentOrg?.name}`;
  const { path, url } = useRouteMatch();

  const [breadcrumb, setBreadcrumb] = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb(
      <>
        <Link
          className="link-button"
          to={{ pathname: `/${org}`, search: location.search }}
        >
          {orgContext?.currentOrg?.name}
        </Link>
        <div className="breadcrumb-separator">/</div>
        <Link
          className="link-button"
          to={{ pathname: `/${org}/domains`, search: location.search }}
        >
          Domains
        </Link>
        <div className="breadcrumb-separator">/</div>
        <Link
          className="link-button"
          to={{ pathname: `/${org}/domains/article`, search: location.search }}
        >
          Article
        </Link>
      </>
    );
  }, [orgContext?.currentOrg, history.location]);

  return (
    <div className="flex flex-column w-full">
      <TopNav onlySidePadding>
        <div className="flex title-secondary w-1/4">Article domain</div>
        <div className="flex flex-1 content-center">
          <NavLink
            className={`${styles["tab"]} flex items-center space-x-sm`}
            to={{ pathname: `${url}/overview`, search: location.search }}
          >
            <FontAwesomeIcon className="" icon={faHome} />
            <div>Overview</div>
          </NavLink>
          <NavLink
            className={`${styles["tab"]} flex items-center space-x-sm`}
            to={{ pathname: `${url}/build`, search: location.search }}
          >
            <FontAwesomeIcon className="" icon={faPencilRuler} />
            <div>Build</div>
          </NavLink>
          {/* <NavLink
              className={`${styles["navlink"]} flex items-center space-x-sm`}
              to={`${url}/implementations`}
            >
              <FontAwesomeIcon icon={faCode} />
              <div>Implementations</div>
            </NavLink> */}
          <NavLink
            className={`${styles["tab"]} flex items-center space-x-sm`}
            to={{ pathname: `${url}/insights`, search: location.search }}
          >
            <FontAwesomeIcon className="" icon={faChartLine} />
            <div>Insights</div>
          </NavLink>
          <NavLink
            className={`${styles["tab"]} flex items-center space-x-sm`}
            to={{ pathname: `${url}/history`, search: location.search }}
          >
            <FontAwesomeIcon className="" icon={faHistory} />
            <div>History</div>
          </NavLink>
          <NavLink
            className={`${styles["tab"]} flex items-center space-x-sm`}
            to={{ pathname: `${url}/settings`, search: location.search }}
          >
            <FontAwesomeIcon className="" icon={faCog} />
            <div>Settings</div>
          </NavLink>
        </div>
        <div className="flex w-1/4"></div>
      </TopNav>
      <Switch>
        <Redirect exact from={path} to={`${path}/build`} />
        <Route path={`${path}/build`}>
          <Build />
        </Route>
      </Switch>
    </div>
  );
}
