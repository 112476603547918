import { useEffect, useState } from "react";
import "./App.css";
import logo from "./swap_icon.svg";
import { Route, Switch } from "react-router-dom";
import { AppNav, AppTopNav } from "./components/nav";
import {
  Organization,
  OrganizationContext,
  SelectOrganization,
} from "./features/organization";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { gql, useQuery } from "@apollo/react-hooks";
import { AppRoutes } from "./AppRoutes";
import { BreadcrumbContext } from "./components/nav/breadcrumb-context";

function App(): JSX.Element {
  const [theme, setTheme] = useState("light");
  // const nextTheme = theme === "light" ? "dark" : "light";

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  const breadcrumb = useState<JSX.Element | undefined>(undefined);

  const [currentOrg, setCurrentOrg] = useState<Organization | undefined>(
    undefined
  );
  const [orgs, setOrgs] = useState<Organization[] | undefined>(undefined);

  const GET_ORGS = gql`
    query {
      organizations {
        name
        color
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_ORGS, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (data) {
      setOrgs(data.organizations);
    }
  }, [data]);

  return (
    <div className="app">
      <OrganizationContext.Provider
        value={{
          currentOrg: currentOrg,
          setCurrentOrg: setCurrentOrg,
          orgs: orgs,
          setOrgs: setOrgs,
        }}
      >
        <BreadcrumbContext.Provider value={breadcrumb}>
          <Switch>
            <Route exact path="/">
              <SelectOrganization />
            </Route>
            <Route path="/:org">
              <AppNav logo={logo} />
              <div className="main">
                <div className="feature-container">
                  <AppTopNav />
                  <AppRoutes />
                </div>
              </div>
            </Route>
            <Route path="*">
              <div>404</div>
            </Route>
          </Switch>
        </BreadcrumbContext.Provider>
      </OrganizationContext.Provider>
    </div>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <div>loading</div>,
});
