import { PropsWithChildren } from "react";
import styles from "./ListView.module.css";

export enum DivideStyle {
  Line,
  Space,
  None,
}

export type ListViewProps = { items: JSX.Element[]; divideStyle?: DivideStyle };

export function ListView(props: ListViewProps): JSX.Element {
  return (
    <div className={`${styles["list-view"]}`}>
      {props.items.map((item, index) => {
        return (
          <>
            <div
              key={item.key}
              className={`w-full ${
                index < props.items.length - 1 &&
                (props.divideStyle === DivideStyle.Space ? styles["space"] : "")
              }`}
            >
              {item}
            </div>
            {index < props.items.length - 1 &&
              props.divideStyle === DivideStyle.Line && (
                <div className={styles["line"]} />
              )}
          </>
        );
      })}
    </div>
  );
}
