import { faBox, faBoxOpen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Sparklines, SparklinesCurve } from "react-sparklines";
import { SearchInput } from "../../components/input/SearchInput";
import { ButtonLink } from "../../components/link";
import { BreadcrumbContext } from "../../components/nav/breadcrumb-context";
import { Select } from "../../components/select";
import { OrganizationContext } from "../organization";
import styles from "./Domains.module.css";

export function Domains(): JSX.Element {
  const { org }: any = useParams();
  const location = useLocation();
  const history = useHistory();
  const orgContext = useContext(OrganizationContext);
  document.title = `Domains - ${orgContext?.currentOrg?.name}`;

  const { url } = useRouteMatch();
  const domains = [
    { slug: "article", name: "Article" },
    { slug: "user", name: "User" },
  ];

  const [breadcrumb, setBreadcrumb] = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb(
      <>
        <Link
          className="link-button"
          to={{ pathname: `/${org}`, search: location.search }}
        >
          {orgContext?.currentOrg?.name}
        </Link>
        <div className="breadcrumb-separator">/</div>
        <Link
          className="link-button"
          to={{ pathname: `/${org}/domains`, search: location.search }}
        >
          Domains
        </Link>
      </>
    );
  }, [orgContext?.currentOrg, history.location]);

  return (
    <div className={`feature-content w-lg space-y-2xl`}>
      <div className="title">Domains</div>
      <div className="flex space-x-xs">
        <div className="flex flex-1 space-x-sm">
          <div className="w-1/2">
            <SearchInput placeholder="Filter domains" />
          </div>
          <Select>Most recent</Select>
        </div>
        <ButtonLink to={{ pathname: `${url}/new`, search: location.search }}>
          <div>New domain</div>
        </ButtonLink>
      </div>
      {/* <ListView
        divideStyle={DivideStyle.Line}
        items={domains.map((domain, index) => {
          return (
            <div className="section">
              <div className="flex">
                <Link key={index} to={`${url}/${domain.slug}`}>
                  {domain.name}
                </Link>
              </div>
            </div>
          );
        })}
      /> */}
      <div className="flex flex-column">
        {domains.map((domain, index) => {
          return (
            <div key={index}>
              <div className={`flex items-center ${styles["domain"]}`}>
                <div className="flex flex-1 flex-column space-y-lg">
                  <div className="flex items-center space-x-md">
                    <Link key={index} to={`${url}/${domain.slug}`}>
                      <div className="title-secondary link">{domain.name}</div>
                    </Link>
                    <div className={`${styles["private"]} text-xs`}>
                      Private
                    </div>
                  </div>
                  <div className="flex space-x-xl items-center">
                    <div className="flex items-center space-x-xs very-subtle">
                      <FontAwesomeIcon className="" icon={faBox} />
                      <div>2 Dependencies</div>
                    </div>
                    <div className="flex items-center space-x-xs very-subtle">
                      <FontAwesomeIcon className="" icon={faBoxOpen} />
                      <div>1 Dependent</div>
                    </div>
                    <div className="very-subtle">Updated today</div>
                  </div>
                </div>
                <div>
                  <div className={`${styles["activity"]}`}>
                    <Sparklines
                      data={Array.from({ length: 20 }, () =>
                        Math.floor(Math.random() * 30)
                      )}
                      height={50}
                    >
                      {/* <SparklinesCurve color="#22D3EE" /> */}
                      <SparklinesCurve color="#2DD4BF" />
                      {/* <SparklinesCurve color="#18181B" /> */}
                    </Sparklines>
                  </div>
                </div>
              </div>
              {index < domains.length - 1 && (
                <div className="h-separator"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
