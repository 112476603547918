import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren, useState } from "react";
import styles from "./Expander.module.css";

export type ExpanderProperties = PropsWithChildren<{
  header: JSX.Element;
}>;

export function Expander(props: ExpanderProperties): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="flex flex-column">
      <div
        onClick={() => setExpanded(!expanded)}
        className={`flex items-center noselect ${styles["expandable"]} ${
          expanded ? styles["expanded"] : ""
        }`}
      >
        <div className={`${styles["arrow"]}`}>
          {expanded ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
        {props.header}
      </div>
      {expanded && (
        <div className={`${styles["content"]}`}>{props.children}</div>
      )}
    </div>
  );
}
