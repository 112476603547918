import { Elements } from "react-flow-renderer";

const position = { x: 0, y: 0 };
export function getInitialElements(onNew: (event: any) => void) {
  return [
    {
      id: "state",
      sourcePosition: "right",
      type: "input",
      className: "dark-node",
      data: { label: "State" },
      position: position,
    },
    {
      id: "properties",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Properties", onNew: onNew },
      position: position,
      type: "additive",
    },
    {
      id: "prop-id",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "ID", dataType: "key" },
      type: "property",
      position: position,
    },
    {
      id: "prop-title",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Title", dataType: "text" },
      type: "property",
      position: position,
    },
    {
      id: "prop-author",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Author", dataType: "relationship" },
      type: "property",
      position: position,
    },
    {
      id: "prop-body",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Body", dataType: "text" },
      type: "property",
      position: position,
    },
    {
      id: "prop-tags",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Tags", dataType: "list" },
      type: "property",
      position: position,
    },
    {
      id: "prop-created",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Created", dataType: "date" },
      type: "property",
      position: position,
    },
    {
      id: "prop-lastupdated",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Last Updated", dataType: "date" },
      type: "property",
      position: position,
    },
    {
      id: "actions",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Actions" },
      position: position,
    },
    {
      id: "create",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Create Article" },
      type: "action",
      position: position,
    },
    {
      id: "query",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Query Article" },
      type: "action",
      position: position,
    },
    {
      id: "archive",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Archive Article" },
      type: "action",
      position: position,
    },
    {
      id: "event-created",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Article Created" },
      type: "event",
      position: position,
    },
    {
      id: "event-queried",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Article Queried" },
      type: "event",
      position: position,
    },
    {
      id: "event-archived",
      sourcePosition: "right",
      targetPosition: "left",
      data: { label: "Article Archived" },
      type: "event",
      position: position,
    },

    {
      id: "e1",
      source: "state",
      type: "smoothstep",
      target: "properties",
      animated: true,
    },
    {
      id: "e2",
      source: "properties",
      type: "smoothstep",
      target: "prop-id",
      animated: true,
    },
    {
      id: "e3",
      source: "properties",
      type: "smoothstep",
      target: "prop-title",
      animated: true,
    },
    {
      id: "e4",
      source: "properties",
      type: "smoothstep",
      target: "prop-author",
      animated: true,
    },
    {
      id: "e5",
      source: "properties",
      type: "smoothstep",
      target: "prop-body",
      animated: true,
    },
    {
      id: "e6",
      source: "properties",
      type: "smoothstep",
      target: "prop-tags",
      animated: true,
    },
    {
      id: "e7",
      source: "properties",
      type: "smoothstep",
      target: "prop-created",
      animated: true,
    },
    {
      id: "e8",
      source: "properties",
      type: "smoothstep",
      target: "prop-lastupdated",
      animated: true,
    },
    {
      id: "e9",
      source: "state",
      type: "smoothstep",
      target: "actions",
      animated: true,
    },
    {
      id: "e10",
      source: "actions",
      type: "smoothstep",
      target: "create",
      animated: true,
    },
    {
      id: "e11",
      source: "actions",
      type: "smoothstep",
      target: "query",
      // animated: true,
    },
    {
      id: "e12",
      source: "actions",
      type: "smoothstep",
      target: "archive",
      animated: true,
    },
    {
      id: "asdf1",
      source: "create",
      type: "smoothstep",
      target: "event-created",
      arrowHeadType: "arrow",
      animated: true,
    },
    {
      id: "asdf2",
      source: "query",
      type: "smoothstep",
      target: "event-queried",
      arrowHeadType: "arrow",
      animated: true,
    },
    {
      id: "asdf3",
      source: "archive",
      type: "smoothstep",
      target: "event-archived",
      arrowHeadType: "arrow",
      animated: true,
    },
  ] as Elements;
}
