import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styles from "./ButtonLink.module.css";

export type ButtonLinkProps = PropsWithChildren<{
  to: any;
}>;
export function ButtonLink(props: ButtonLinkProps): JSX.Element {
  return (
    <Link className={styles["button-link"]} to={props.to}>
      {props.children}
    </Link>
  );
}
