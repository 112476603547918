import { createContext, useContext, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../components/nav/breadcrumb-context";
import { OrganizationContext } from "../organization";

export function Home(): JSX.Element {
  const { org }: any = useParams();
  const location = useLocation();
  const orgContext = useContext(OrganizationContext);
  document.title = `Dashboard - ${orgContext?.currentOrg?.name}`;
  const [breadcrumb, setBreadcrumb] = useContext(BreadcrumbContext);

  useEffect(() => {
    if (orgContext?.currentOrg) {
      setBreadcrumb(
        <Link
          className="link-button"
          to={{ pathname: `/${org}`, search: location.search }}
        >
          {orgContext?.currentOrg?.name}
        </Link>
      );
    }
  }, [orgContext?.currentOrg]);

  return (
    <div className="feature-container">
      <div className={`feature-content w-lg space-y-2xl`}>
        <div className="title">Dashboard</div>
      </div>
    </div>
  );
}
