import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { IconLink } from "../../components/link";
import styles from "./NewDomain.module.css";
import { ErrorMessage, Formik } from "formik";
import { Select } from "../../components/select";
import { SelectCard } from "../../components/select-card";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { DivideStyle, ListView } from "../../components/list";
import { OrganizationContext } from "../organization";
import { BreadcrumbContext } from "../../components/nav/breadcrumb-context";

export function NewDomain(): JSX.Element {
  const { org }: any = useParams();
  const location = useLocation();
  const orgContext = useContext(OrganizationContext);
  document.title = `New domain - ${orgContext?.currentOrg?.name}`;
  const history = useHistory();
  const { url } = useRouteMatch();
  const backUrl = url.substring(0, url.lastIndexOf("/"));
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [domainName, setDomainName] = useState<string | null>(null);
  const [featureName, setFeatureName] = useState<string | null>(null);

  const [breadcrumb, setBreadcrumb] = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb(
      <>
        <Link className="link-button" to={`/${org}`}>
          {orgContext?.currentOrg?.name}
        </Link>
        <div className="breadcrumb-separator">/</div>
        <Link className="link-button" to={`/${org}/domains`}>
          Domains
        </Link>
        <div className="breadcrumb-separator">/</div>
        <Link className="link-button" to={`/${org}/domains/new`}>
          New domain
        </Link>
      </>
    );
  }, [orgContext?.currentOrg]);

  const steps = (
    <>
      <div className={`section ${currentStep !== 1 ? "inactive" : "active"}`}>
        <Formik
          initialValues={{ name: "" }}
          validate={(values) => {
            const errors: any = {};
            if (!values.name) {
              errors.name = "Name is required.";
            } else if (values.name.length < 3) {
              errors.name = "Name must be at least 3 characters.";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setDomainName(values.name);
            setCurrentStep(2);
            setSubmitting(false);
          }}
          validateOnChange
          isInitialValid={false}
        >
          {({
            values,
            errors,
            dirty,
            isValid,
            handleSubmit,
            handleChange,
            isSubmitting,
          }) => (
            <form
              className="flex flex-column space-y-lg"
              onSubmit={handleSubmit}
            >
              <div className="flex items-center title-secondary space-x-lg">
                <div className="flex-1">
                  Name your domain
                  {currentStep > 1 && <span>: {domainName}</span>}
                </div>
                <div>
                  {currentStep > 1 && (
                    <FontAwesomeIcon
                      className={`${styles["valid"]} text-2xl`}
                      icon={faCheckCircle}
                    />
                  )}
                </div>
              </div>
              {currentStep === 1 && (
                <React.Fragment>
                  <input
                    type="text"
                    className="primary"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Name"
                    autoFocus
                  />
                  <ErrorMessage name="name" component="div" />
                  <button
                    type="submit"
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    Continue
                  </button>
                </React.Fragment>
              )}
            </form>
          )}
        </Formik>
      </div>
      <div className={`section ${currentStep !== 2 ? "inactive" : "active"}`}>
        <Formik
          initialValues={{ name: "" }}
          validate={(values) => {
            const errors: any = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setFeatureName("First feature");
            setCurrentStep(3);
            setSubmitting(false);
          }}
          validateOnChange
        >
          {({
            values,
            errors,
            dirty,
            isValid,
            handleSubmit,
            handleChange,
            isSubmitting,
          }) => (
            <form
              className="flex flex-column space-y-lg"
              onSubmit={handleSubmit}
            >
              <div className="flex items-center title-secondary space-x-lg">
                <div className="flex-1">
                  Associate with a feature
                  {currentStep > 2 && <span>: {featureName}</span>}
                </div>
                <div>
                  {currentStep > 2 && (
                    <FontAwesomeIcon
                      className={`${styles["valid"]} text-2xl`}
                      icon={faCheckCircle}
                    />
                  )}
                </div>
              </div>
              {currentStep === 2 && (
                <React.Fragment>
                  <Select>First feature</Select>
                  <button
                    autoFocus
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Continue
                  </button>
                </React.Fragment>
              )}
            </form>
          )}
        </Formik>
      </div>
      <div className={`section ${currentStep !== 3 ? "inactive" : "active"}`}>
        <Formik
          initialValues={{ name: "" }}
          validate={(values) => {
            const errors: any = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            history.push("/domains/article");
          }}
          validateOnChange
        >
          {({
            values,
            errors,
            dirty,
            isValid,
            handleSubmit,
            handleChange,
            isSubmitting,
          }) => (
            <form
              className="flex flex-column space-y-lg"
              onSubmit={handleSubmit}
            >
              <div className="flex items-center title-secondary space-x-lg">
                <div className="flex-1">Choose a template</div>
                <div>
                  {currentStep > 3 && (
                    <FontAwesomeIcon
                      className={`${styles["valid"]} text-2xl`}
                      icon={faCheckCircle}
                    />
                  )}
                </div>
              </div>
              {currentStep === 3 && (
                <React.Fragment>
                  <div className="flex flex-column space-y-xs">
                    <SelectCard selected>
                      <div className="title-tertiary">Basic Starter</div>
                      <div>Includes recommended basic elements</div>
                    </SelectCard>
                    <SelectCard>
                      <div className="title-tertiary">Copy</div>
                      <div>Make a copy of another domain</div>
                    </SelectCard>
                    <SelectCard>
                      <div className="title-tertiary">Hub</div>
                      <div>Choose a template from the marketplace</div>
                    </SelectCard>
                  </div>
                  <button
                    autoFocus
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Start building
                  </button>
                </React.Fragment>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );

  return (
    <div className="feature-content w-md space-y-2xl">
      <div className="flex flex-start">
        <IconLink
          to={{ pathname: backUrl, search: location.search }}
          icon={faArrowLeft}
          solidIcon={faArrowLeft}
        >
          <div>Back</div>
        </IconLink>
      </div>
      <div className="title">Create a domain</div>
      <div className="flex flex-column space-y-lg">
        {steps}
        {/* <ListView items={steps} divideStyle={DivideStyle.Line} /> */}
      </div>
    </div>
  );
}
