import { useState, MouseEvent } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Position,
  Node,
} from "react-flow-renderer";
import { Select } from "../../../components/select";
import styles from "./Build.module.css";
import { PropertyNode } from "./flow/PropertyNode";
import { ActionNode } from "./flow/ActionNode";
import { AdditiveNode } from "./flow/AdditiveNode";
import { EventNode } from "./flow/EventNode";
import { getInitialElements } from "./initialElements";
import { Formik } from "formik";
import { Route, useHistory, useRouteMatch } from "react-router";
import { Link, Switch, useLocation } from "react-router-dom";
import { Action } from "./action";
import { getLayoutedElements } from "./flow/layoutedElements";
import { SearchInput } from "../../../components/input/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronDown,
  faHashtag,
  faKey,
  faList,
  faProjectDiagram,
  faText,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBolt,
  faLockAlt,
  faQuestion,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";
import { Expander } from "../../../components/expander";

export function Build(): JSX.Element {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const domain = require("./article.json");

  const onNew = (event: any) => {
    setSelectedIntention(null);
    setNewIntention({});
  };

  let initialElements = getInitialElements(onNew);

  let flowInstance: any = null;
  const layoutedElements = getLayoutedElements(initialElements, 150, 50);

  const onElementClick = (event: any, element: any) => {
    if (element.id !== "properties" && element.id !== "state") {
      setNewIntention(null);
      setSelectedIntention(element);
    } else {
      setSelectedIntention(null);
    }
  };

  const nodeTypes = {
    property: PropertyNode,
    action: ActionNode,
    event: EventNode,
    additive: AdditiveNode,
  };

  const onLoad = (reactFlowInstance: any) => {
    flowInstance = reactFlowInstance;
    // reactFlowInstance.fitView();
  };

  const onNodeDoubleClick = (event: MouseEvent, node: Node<any>) => {
    console.log(`dblclciiick: ${JSON.stringify(node)}`);
    if (node.type === "action") {
      history.push(`${url}/action/${node.id}`);
      // setElements(getActionElements());
    }
  };

  const onPaneClick = (event: MouseEvent) => {
    setSelectedIntention(null);
    setNewIntention(null);
  };

  const [elements, setElements] = useState(layoutedElements);
  const [selectedIntention, setSelectedIntention] = useState<any>(null);
  const [newIntention, setNewIntention] = useState<any>(null);
  // const [action, setAction] = useState<any>(null);

  const properties = domain.spec.appliedIntentions[0].has[0].has;

  return (
    <div className={`${styles["build"]} space-y-lg`}>
      <Switch>
        <Route path={path} exact>
          <div className="flex flex-1 space-x-sm">
            <div className="flex w-sm space-x-sm">
              <SearchInput className="flex-1" placeholder="Filter" />
              <Select className={styles["type-filter"]}>All kinds</Select>
            </div>
            <div className="flex flex-1 content-end">
              <button
                className="flex primary items-center space-x-md"
                type="button"
              >
                <div className="">New</div>
                <FontAwesomeIcon className="" icon={faChevronDown} />
              </button>
            </div>
          </div>
          <div className="flex space-x-lg">
            <div className="flex flex-column flex-1 space-y-md">
              <div className="title-subtle">Model</div>
              <input type="text" placeholder="New property name..." />
              <div className="flex flex-column">
                {properties.map((property: any) => (
                  <Expander
                    key={property.name}
                    header={
                      <div className="flex-1 flex space-x-lg items-center">
                        <div className={styles["type-icon"]}>
                          {{
                            slug: <FontAwesomeIcon icon={faKey} />,
                            text: <FontAwesomeIcon icon={faText} />,
                            collection: <FontAwesomeIcon icon={faList} />,
                            number: <FontAwesomeIcon icon={faHashtag} />,
                            relationship: (
                              <FontAwesomeIcon icon={faProjectDiagram} />
                            ),
                            datetime: <FontAwesomeIcon icon={faCalendarAlt} />,
                          }[property.intention as string] || (
                            <FontAwesomeIcon icon={faQuestion} />
                          )}
                        </div>
                        <div className="title-tertiary flex-1">
                          {property.displayName}
                        </div>
                        {property.has && (
                          <div className="subtle">
                            {property.has[0].intention.charAt(0).toUpperCase() +
                              property.has[0].intention.slice(1)}
                          </div>
                        )}
                      </div>
                    }
                  >
                    <div className="flex flex-column space-y-xl">
                      <div className="flex flex-column space-y-xs">
                        <div className="label">Name</div>
                        <input
                          type="text"
                          defaultValue={property.displayName}
                        />
                      </div>
                      <div className="flex flex-column space-y-xs">
                        <div className="label">Type</div>
                        <Select>
                          {property.intention.charAt(0).toUpperCase() +
                            property.intention.slice(1)}
                        </Select>
                      </div>
                      {property.has && (
                        <div className="flex flex-column space-y-xs">
                          <div className="label">Of</div>
                          <Select>
                            {property.has[0].intention.charAt(0).toUpperCase() +
                              property.has[0].intention.slice(1)}
                          </Select>
                        </div>
                      )}
                    </div>
                  </Expander>
                ))}
              </div>
            </div>
            <div className="flex flex-column flex-1 space-y-md">
              <div className="flex space-x-sm items-center title-subtle">
                <div>Actions</div>
              </div>
              <input type="text" placeholder="New action name..." />
              <Link
                className="card space-y-lg"
                to={{
                  pathname: `${url}/action/create-article`,
                  search: location.search,
                }}
              >
                <div className="flex flex-column space-y-xs">
                  <div className="flex items-center space-x-sm title-tertiary">
                    <div className="flex-1">Query</div>
                  </div>
                  <div className="">Find and retrieve articles</div>
                </div>
                <div className="flex items-center space-x-lg">
                  <div className="flex items-center space-x-xs subtle">
                    <FontAwesomeIcon className="teal-500" icon={faPlay} />
                    <div>On-Demand</div>
                  </div>
                  <div className="flex items-center space-x-xs subtle">
                    <FontAwesomeIcon className="yellow-400" icon={faBolt} />
                    <div>2 Events</div>
                  </div>
                  <div className="flex items-center space-x-xs subtle">
                    {/* <FontAwesomeIcon icon={faHistory} /> */}
                    <div>Updated today</div>
                  </div>
                </div>
              </Link>
              <Link
                className="card space-y-lg"
                to={{
                  pathname: `${url}/action/create-article`,
                  search: location.search,
                }}
              >
                <div className="flex flex-column space-y-xs">
                  <div className="flex items-center space-x-sm title-tertiary">
                    <div className="flex-1">Create</div>
                    <FontAwesomeIcon className="yellow-500" icon={faLockAlt} />
                  </div>
                  <div className="">Create a new article</div>
                </div>
                <div className="flex items-center space-x-lg">
                  <div className="flex items-center space-x-xs subtle">
                    <FontAwesomeIcon className="teal-500" icon={faPlay} />
                    <div>On-Demand</div>
                  </div>
                  <div className="flex items-center space-x-xs subtle">
                    <FontAwesomeIcon className="yellow-400" icon={faBolt} />
                    <div>2 Events</div>
                  </div>
                  <div className="flex items-center space-x-xs subtle">
                    {/* <FontAwesomeIcon icon={faHistory} /> */}
                    <div>Updated today</div>
                  </div>
                </div>
              </Link>
              <Link
                className="card space-y-lg"
                to={{
                  pathname: `${url}/action/create-article`,
                  search: location.search,
                }}
              >
                <div className="flex flex-column space-y-xs">
                  <div className="flex items-center space-x-sm title-tertiary">
                    <div className="flex-1">Archive</div>
                    <FontAwesomeIcon className="yellow-500" icon={faLockAlt} />
                  </div>
                  <div className="">Archive an article</div>
                </div>
                <div className="flex items-center space-x-lg">
                  <div className="flex items-center space-x-xs subtle">
                    <FontAwesomeIcon className="teal-500" icon={faPlay} />
                    <div>On-Demand</div>
                  </div>
                  <div className="flex items-center space-x-xs subtle">
                    <FontAwesomeIcon className="yellow-400" icon={faBolt} />
                    <div>2 Events</div>
                  </div>
                  <div className="flex items-center space-x-xs subtle">
                    {/* <FontAwesomeIcon icon={faHistory} /> */}
                    <div>Updated today</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {/* <div className="flex">
            <div
              className={`${styles["intentions-flow"]} ${styles["flow-domain-height"]}`}
            >
              <ReactFlow
                elements={elements}
                nodesDraggable={false}
                onElementClick={onElementClick}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                onNodeDoubleClick={onNodeDoubleClick}
                onPaneClick={onPaneClick}
              >
                {selectedIntention && (
                  <div
                    className={`${styles["intention-container"]} w-sm space-y-xs`}
                  >
                    <div className={`${styles["intention"]} space-y-sm`}>
                      <div className="text-lg font-medium">
                        {selectedIntention?.data?.label}
                      </div>
                    </div>
                  </div>
                )}
                {newIntention && (
                  <div className={`${styles["intention-container"]} w-sm`}>
                    <Formik
                      initialValues={{ name: "", type: "" }}
                      validate={(values) => {
                        const errors: any = {};
                        if (!values.name) {
                          errors.name = "Name is required.";
                        } else if (values.name.length < 3) {
                          errors.name = "Name must be at least 3 characters.";
                        }
                        return errors;
                      }}
                      validateOnChange
                      isInitialValid={false}
                      onSubmit={(values, { setSubmitting }) => {
                        setElements(
                          getLayoutedElements(
                            [
                              ...elements,
                              {
                                id: values.name.toLowerCase(),
                                sourcePosition: Position.Right,
                                targetPosition: Position.Left,
                                data: { label: values.name, dataType: "list" },
                                type: "property",
                                position: { x: 0, y: 0 },
                              },
                              {
                                id: `e-${values.name.toLowerCase()}`,
                                source: "properties",
                                type: "smoothstep",
                                target: values.name.toLowerCase(),
                                animated: true,
                              },
                            ],
                            150,
                            50
                          )
                        );
                        setNewIntention(null);
                      }}
                    >
                      {({
                        values,
                        errors,
                        dirty,
                        isValid,
                        handleSubmit,
                        handleChange,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit} className="space-y-xs">
                          <div className={`${styles["intention"]} space-y-sm`}>
                            <div className="title-tertiary">
                              New property name
                            </div>
                            <input
                              autoFocus
                              type="text"
                              placeholder="Name..."
                              className="w-full"
                              name="name"
                              onChange={handleChange}
                              value={values.name}
                            />
                            <div className="title-tertiary">Type</div>
                            <Select>Collection</Select>
                          </div>
                          <button
                            type="submit"
                            className="primary w-full"
                            disabled={isSubmitting || !dirty || !isValid}
                          >
                            Add
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                )}
                <MiniMap nodeBorderRadius={2} />
                <Controls />
                <Background color="#18181b" gap={16} />
              </ReactFlow>
            </div>
          </div> */}
        </Route>
        <Route path={`${path}/action/:action`}>
          <Action />
        </Route>
      </Switch>
    </div>
  );
}
