import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithChildren } from "react";
import styles from "./Select.module.css";

export type SelectProps = PropsWithChildren<{ className?: string }>;

export function Select(props: SelectProps): JSX.Element {
  return (
    <div
      className={`${styles["select"]} ${
        props.className ? props.className : ""
      } flex items-center space-x-sm`}
    >
      <div className="flex-1">{props.children}</div>
      <FontAwesomeIcon className="" icon={faChevronDown} />
    </div>
  );
}
