import { PropsWithChildren } from "react";
import styles from "./TopNav.module.css";

export type TopNavProps = PropsWithChildren<{ onlySidePadding?: boolean }>;
export function TopNav(props: TopNavProps): JSX.Element {
  return (
    <div
      className={`${styles["top-nav"]} ${
        props.onlySidePadding ? styles["side-padding"] : styles["all-padding"]
      } flex items-center`}
    >
      <div className="flex flex-1 items-center">{props.children}</div>
    </div>
  );
}
