import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Persona } from "../../components/persona";
import { Organization } from "./Organization";
import { OrganizationContext } from "./organization-context";

export function SelectOrganization() {
  document.title = "Swap";
  const location = useLocation();
  const orgContext = useContext(OrganizationContext);

  return (
    <div className="feature-container">
      <div className={`feature-content w-lg space-y-lg`}>
        <div className="title">Projects</div>
        <div className="flex flex-column">
          {orgContext?.orgs &&
            (orgContext.orgs as Organization[]).map((org, index) => (
              <Link
                key={index}
                to={{
                  pathname: `/${org.name?.toLowerCase()}`,
                  search: location.search,
                }}
              >
                <div className="section selectable">
                  <div className="flex items-center space-x-md">
                    <Persona
                      isSquare
                      className="text-lg"
                      backgroundColor={org.color ? org.color : "#d4d4d4"}
                    >
                      {org.name?.charAt(0)}
                    </Persona>
                    <div className="title-tertiary">{org.name}</div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}
