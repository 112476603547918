import { PropsWithChildren } from "react";
import styles from "./Nav.module.css";

export type NavProps = PropsWithChildren<{
  className?: string;
  isOnRightSide?: boolean;
}>;

export function Nav(props: NavProps): JSX.Element {
  return (
    <div
      className={`${styles["nav"]} ${props.className ? props.className : ""} ${
        props.isOnRightSide ? styles["right"] : styles["left"]
      }`}
    >
      {props.children}
    </div>
  );
}
