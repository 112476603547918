import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";
import styles from "./IconLink.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link, NavLink } from "react-router-dom";

export type IconLinkProps = PropsWithChildren<{
  to: any;
  icon: IconProp;
  solidIcon: IconProp;
  exact?: boolean;
  isNavLink?: boolean;
  big?: boolean;
}>;

export function IconLink(props: IconLinkProps): JSX.Element {
  return props.isNavLink ? (
    <NavLink
      className={`${styles["icon-link"]} space-x-sm`}
      to={props.to}
      exact={props.exact}
    >
      <div
        className={`${styles["icon"]} ${
          props.big ? styles["big"] : styles["normal"]
        }`}
      >
        <FontAwesomeIcon icon={props.icon} />
        {/* <FontAwesomeIcon className={styles["regular"]} icon={props.icon} />
        <FontAwesomeIcon className={styles["solid"]} icon={props.solidIcon} /> */}
      </div>
      {props.children && props.children}
    </NavLink>
  ) : (
    <Link className={`${styles["icon-link"]} font-medium`} to={props.to}>
      <div
        className={`${styles["icon"]} ${
          props.big ? styles["big"] : styles["normal"]
        } ${styles["non-nav"]}`}
      >
        <FontAwesomeIcon className="text-lg" icon={props.icon} />
      </div>
      {props.children && (
        <div className={styles["label"]}>
          {props.children && props.children}
        </div>
      )}
    </Link>
  );
}
